.lips {
  &__main-image {
    border-radius: 2rem;
  }

  &__images-container {
    & img {
      width: 100%;
      border-radius: 2rem;
      margin-bottom: 1.6rem;
    }
  }
}
