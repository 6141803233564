.wrapper {
  position: relative;
}

.carousel {
  &__left-button,
  &__right-button {
    display: none;
    position: absolute;
    top: 50%;
    margin-top: calc(
      -2.5rem + -1.7rem
    ); // Half the height of the "dots" + half the height of the button itself
    z-index: 3;

    & .btn--sm {
      width: auto !important;
    }

    &--shown {
      display: block;
    }

    &__no-dots {
      margin-top: -1.7rem;
    }

    @media screen and (min-width: 768px) {
      display: block;
    }
  }

  &__left-button {
    left: -1rem;

    @media screen and (min-width: 768px) {
      left: -3rem;
    }

    @media screen and (min-width: 1920px) {
      left: -6rem;
    }
  }

  &__right-button {
    right: -1rem;
    @media screen and (min-width: 768px) {
      right: -3rem;
    }

    @media screen and (min-width: 1920px) {
      right: -6rem;
    }
  }

  &__button-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    transform: translateY(-65%);

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  & img {
    border-radius: 2rem;
  }

  .react-multi-carousel-dot-list {
    position: relative;
    padding-top: 3rem;
  }

  .react-multi-carousel-dot {
    button {
      background-color: #e9e9e9;
      border: none;
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 0.7rem;
      transition: all 0.5s ease-in-out;
    }

    &--active {
      button {
        background-color: #053e51;
      }
    }
  }
}
