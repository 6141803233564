.landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  position: relative;

  @media screen and (min-width: 998px) {
    flex-direction: row;
  }
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 998px) {
    width: 50%;
  }
}
.image-container img {
  width: 100vw;
  height: 50vh;
  object-fit: cover;
  max-height: 100vh;

  @media screen and (min-width: 998px) {
    height: 100vh;
    width: 50vw;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  flex-direction: column;

  & p {
    color: white;
    text-align: center;
    font-size: 30px;

    @media screen and (min-width: 998px) {
      font-size: 40px;
    }
  }

  &__container {
    margin-bottom: 0;

    @media screen and (min-width: 998px) {
      margin-bottom: 18%;
    }
  }

  &__subtext {
    font-size: 28px !important;

    @media screen and (min-width: 998px) {
      font-size: 30px;
    }
  }

  &__left {
    @media screen and (min-width: 998px) {
      padding-bottom: 30px;
      padding-top: 38px;
    }
  }

  &:hover {
    background: transparent;
    transition: background 0.7s linear;

    & p {
      // color: $bluelight;

      width: 100vw;

      @media screen and (min-width: 998px) {
        background-color: $primary;
        transition: background 0.5s linear;
        width: 50vw;
      }
    }
  }
}

.overlay button {
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  background: $dark;
  border: none;
  cursor: pointer;
}
.overlay button:hover {
  background: $primary;
}

.datenschutz {
  background: white;
  border-radius: 1.2rem;
  padding: 0.3rem;
  cursor: pointer;

  &__mobile {
    margin-top: 5rem;
    margin-bottom: 1rem;
  }

  &__desktop {
    align-items: center;
    bottom: 3rem;
    display: flex;
    justify-content: center;
    left: auto;
    padding: 0.6rem;
    position: absolute;
    right: auto;
  }
}
