.home-page {
  &__heading-text {
    width: 65%;
    text-align: center;
    margin: 0 auto;
    margin-top: 1.6rem;
  }

  &__best-team {
    width: 98%;
    margin: 2rem auto;
    background-color: #fff;
    text-align: center;
    padding: 35px 40px 50px;
    border-radius: 20px;
    @include transitionMedium;
    z-index: 1;
    position: relative;
    border: 2px solid;
    border-color: rgba($primary, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      text-align: left;
    }

    &__image {
      border-radius: 2rem;
      margin-bottom: 2rem;

      @media screen and (min-width: 768px) {
        margin-right: 1.6rem;
        margin-bottom: unset;
      }
    }

    &__heading {
      padding-bottom: 1rem;
    }

    &.active,
    &:hover {
      box-shadow: 0px 10px 50px 0px rgba(0, 2, 72, 0.1);
      z-index: 9;
      border-color: #fff;

      .team-media {
        &:before,
        &:after {
          opacity: 1;
        }
      }
    }
    @include respond("phone-land") {
      padding: 20px 30px 30px;
    }
  }

  &__info {
    padding-block: 2rem;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    &__text {
      width: 100%;
      padding-right: 1rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 1.2rem;

      @media screen and (min-width: 768px) {
        width: 50%;
        margin-bottom: unset;
      }

      &__heading {
        padding-bottom: 2rem;
      }
    }

    & img {
      width: auto;
      object-fit: cover;
      height: 300px;

      @media screen and (min-width: 768px) {
        width: 50%;
      }
    }
  }

  &__group-info {
    padding-block: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      padding-bottom: 3.4rem;
    }

    @media screen and (min-width: 1024px) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    & img {
      border-radius: 2rem;
      margin-bottom: 1.2rem;
      max-height: 500px;
      // object-fit: contain;
      @media screen and (min-width: 768px) {
        margin-bottom: 2rem;
      }
    }

    &__heading {
      padding-bottom: 1.6rem;
      @media screen and (min-width: 768px) {
        padding-bottom: 1rem;
      }
    }

    &__image {
      // width: 100%;
      object-fit: cover;
      border-radius: 2rem;
      margin-bottom: 1.2rem;
      max-height: unset !important;
      height: 500px;
      @media screen and (min-width: 768px) {
        width: 50%;
      }
    }

    &__button {
      // margin: 0 auto;
      max-width: 26rem;
      align-self: center;
    }
  }

  &__feature {
    border-radius: 2rem;
    border: 2px solid $bluelight;
    margin-bottom: 3rem;
    z-index: 9;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 998px) {
      flex-direction: row;
      padding: 2rem;

      &--reverse {
        flex-direction: row-reverse;
      }
    }

    & img {
      border-radius: 2rem;
      margin: 1.2rem auto;

      @media screen and (min-width: 998px) {
        margin: unset;
        margin-right: 2rem;
        width: 50%;
        object-fit: contain;
        max-height: 550px;
      }
    }

    &--reverse {
      & img {
        @media screen and (min-width: 998px) {
          margin-left: 2rem;
          margin-right: 0;
        }
      }
    }

    &__heading {
      padding-bottom: 0.6rem;
    }

    &__subheading {
      padding-bottom: 1.6rem;
      color: $secondary;
    }
  }
  &__your-first-visit {
    &__image {
      width: 100%;
      max-height: 500px;
      object-fit: cover;

      @media screen and (min-width: 768px) {
        width: auto;
        max-height: 650px;
      }
    }
  }
}

.text-with-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    padding-top: 1.6rem;
    justify-content: space-between;
  }

  &--reverse {
    @media screen and (min-width: 1024px) {
      flex-direction: row-reverse;
    }
  }

  & img {
    border-radius: 2rem;
    object-fit: contain;
    margin-bottom: 1.2rem;
    max-height: 300px;
    // max-height: 500px;

    @media screen and (min-width: 1024px) {
      // max-width: 50%;
    }
  }

  &__text {
    text-align: justify;
    padding-right: 1.8rem;
    @media screen and (min-width: 1024px) {
      // max-width: 50%;
    }
    // padding-inline: 1rem;
  }
}

.text-with-image-vertical {
  padding-top: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 768px) {
    padding-top: 2.6rem;
  }

  & img {
    border-radius: 2rem;
    margin-bottom: 1rem;
    width: 100%;
    object-fit: cover;
  }
}
