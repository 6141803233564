.public-treatment {
  &__how-it-works {
    border: 1px solid $secondary;
    border-radius: 2rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media screen and (min-width: 1024px) {
        flex-direction: row;
      }

      &__video-box {
        position: relative;
        width: 100%;
        min-height: 400px;
        margin-bottom: 2rem;

        @media screen and (min-width: 1024px) {
          margin-right: 2rem;
          margin-bottom: unset;
        }
      }
    }

    & iframe {
      width: 100%;
      margin-bottom: 1.2rem;
      min-height: 300px;
      border-radius: 2rem;

      @media screen and (min-width: 1024px) {
        width: 60%;
        min-width: 450px;
        min-height: 400px;
        margin-right: 2rem;
      }
    }

    & button {
      margin: 0 auto;
      margin-top: 1rem;
    }
  }

  &__additional-services {
    &__list li {
      list-style-type: disc;
    }
  }

  &__strict-budget {
    &--bold-text {
      font-weight: 600;
    }
  }
}
