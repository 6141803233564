.bruxismus-page {
  &__image-container {
    display: flex;
    justify-content: center;
    align-items: center;

    &__image {
      border-radius: 2rem;
      object-fit: contain;
      align-self: center;
      width: 100%;
      // max-height: 600px;
    }
  }

  &__start-termin {
    border-radius: 2rem;
    border: 1px solid $bluelight;
    padding: 2rem;
    margin-top: 3rem;
  }
}
