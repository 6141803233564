/* ==================
	10.3 Service
=================== */
.help-bx {
  position: relative;
  border-radius: $border-radius-base;
  overflow: hidden;

  .info {
    padding: 70px 50px;
    text-align: center;
    z-index: 1;
    background-color: rgba($primary, 0.9);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
      max-width: 60px;
      margin-bottom: 20px;
    }
  }
  .title {
    color: #fff;
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
    color: #fff;
  }
}

// brochure
.brochure-bx {
  position: relative;
  background-color: #fff;
  border-radius: 6px 6px 4px 4px !important;
  padding: 30px 30px;
  box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid rgba($primary, 0.2);
  border-width: 1px 1px 3px 1px;
  border-bottom-color: $primary;

  .title-head {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding-bottom: 8px;
    position: relative;
    margin-bottom: 30px;
    &:after {
      background: $primary;
      content: "";
      width: 30px;
      height: 3px;
      position: absolute;
      bottom: -2px;
      left: 0;
    }
  }
  @include respond("phone") {
    padding: 15px;
    .title-head {
      margin-bottom: 20px;
    }
  }
}
.download-link {
  position: relative;
  display: block;
  padding: 15px 15px 15px 65px;
  margin-top: 20px;
  border-radius: $border-radius-base;
  background-color: $primary;

  .title {
    font-size: 15px;
    margin: 0;
    line-height: 22px;
    color: #fff;
  }
  span {
    color: #fff;
    font-size: 14px;
    line-height: 18px;
  }
  img {
    width: 40px;
    position: absolute;
    left: 15px;
    top: 18px;
  }
  @include respond("phone") {
    margin-top: 10px;
  }
}

// List Check Squer
.list-check-squer {
  li {
    display: block;
    color: $dark;
    font-size: 20px;
    font-family: "Roboto Condensed";
    font-weight: 500;
    position: relative;
    padding: 8px 15px 7px 50px;
    margin-bottom: 10px;
    line-height: 20px;

    &:before {
      content: "\f00c";
      font-family: "Line Awesome Free";
      font-weight: 900;
      background-color: $primary;
      @include transitionMedium;
      width: 35px;
      height: 35px;
      display: block;
      text-align: center;
      line-height: 35px;
      color: #fff;
      position: absolute;
      left: 0;
      top: 50%;
      border-radius: 4px;
      transform: translateY(-50%);
    }
    &:hover {
      &:before {
        background-color: $secondary;
      }
    }
    @include respond("phone") {
      font-size: 18px;
      padding: 8px 15px 7px 46px;
    }
  }
}

.services-page {
  &__color-selection {
    padding: 2rem;
    border-radius: 2rem;
    // background-color: #f9f9f9;
    border: 2px solid $border-color;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 1.6rem;
  }
}
