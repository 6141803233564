.contacts {
  &__main {
    padding: 2rem;
    border-radius: 2rem;
    border: 1px solid $primary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
  }

  &__working-hours,
  &__contact-hours {
    padding: 2rem;
    border-radius: 2rem;
    border: 1px solid $secondary;
    margin-bottom: 3rem;
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__day {
      display: flex;
      justify-content: space-between;
      max-width: 260px;
      min-width: 250px;
    }
  }
}
