.private-treatment {
  &__content {
    & iframe {
      width: 100%;
      margin: 0 auto;
      height: 280px;

      @media screen and (min-width: 768px) {
        height: 400px;
      }
      @media screen and (min-width: 1024px) {
        height: 540px;
      }
    }
    &__heading {
      text-align: center;
    }
    &--bold {
      font-weight: 600;
    }
  }

  & ul {
    padding-left: 20px;
    & li {
      list-style-type: disc;
    }
  }
}
