.diagnostic-text-with-image,
.diagnostic-text-with-image--reverse {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #feb354;
  border-radius: 2rem;
  padding: 2rem;

  & img {
    width: 100%;
    border-radius: 2rem;
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
    & img {
      width: 50%;
      margin-left: 2rem;
    }
  }
}

.diagnostic-text-with-image--reverse {
  @media screen and (min-width: 992px) {
    flex-direction: row-reverse;
    & img {
      margin-right: 2rem;
      margin-left: unset;
    }
  }
}
